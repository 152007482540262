const {
  setConfigs,
  getHtml,
  getSaveCardsHtml,
  getPaymentMethodsHtml,
  getCardRemoveModalHtml,
  getFailedModalHtml,
  getButtonsHtml,
  checkSaveCardsExist,
  checkRender,
  getHtmlErorrModal,
  setEventListenerForRadioButtons,
  getApplePayNotSupportedHtml,
  checkFormsofPayments,
  replaceSdk,
  getTotalAmountHtml,
  getCssTheme,
  getPopupModalHtml,
  validateSetupPreload,
  findPaymentConfig,
  handleApplePayStyles,
  handleMobilePayStyles,
  isObjectEmpty,
  isOnlyApplePay,
  getTamaraWidget,
  getInstallmentMehtodLabels,
  getTabbyWidget,
} = require('./util');
const { setupSentry } = require('./sentry');
const { getPaymentDetails, updateTxnStatus } = require('./http');
const { mainConfig, elements } = require('./variables');
const {
  _applePayAvailable,
  _startApplePaySession,
  _openAppleWalletApp,
  _isWalletActive,
} = require('./apple');
const { Selector } = require('./selector');
const { onGooglePayLoaded } = require('./googlePay');
const { $t } = require('./translate');
const { installmentsNotAvailable } = require('./html');

Checkout = typeof Checkout === 'undefined' ? {} : Checkout;

const curScriptElement = document.currentScript;
Selector.setCurrentScript(curScriptElement);

const setEvents = (applePay, isWalletActive, stcPay, urPay) => {
  const payMethods = documentCheckout.querySelectorAll(
    '.' + elements.paymentMethod
  );
  const cards = documentCheckout.querySelector('#' + elements.savedCardsBlock);
  let isPrefferedCard = false;
  if (cards) {
    const savedCards = documentCheckout.querySelectorAll(
      '.' + elements.accordionItemRadio
    );
    savedCards.forEach((element) => {
      if (
        !(element.getAttribute('data-is_expired') === 'true') &&
        element.getAttribute('data-is_preferred') === 'true'
      ) {
        element.checked = true;
        Selector.selectePayMethod(element);
        isPrefferedCard = true;
      }
    });

    if (!isPrefferedCard) {
      if (
        savedCards[0] &&
        savedCards[0].getAttribute('data-is_expired') === 'false'
      ) {
        savedCards[0].checked = true;
        Selector.selectePayMethod(savedCards[0]);
      }
    }
  }

  if (payMethods.length == 1 && !cards) {
    Selector.selectePayMethod(payMethods[0]);
  }

  payMethods.forEach((element) => {
    element.addEventListener(
      'click',
      function () {
        Selector.selectePayMethod(this);
      },
      true
    );
  });

  documentCheckout
    .querySelectorAll('.' + elements.accordionItemRadio)
    .forEach((element) => {
      if (!(element.getAttribute('data-is_expired') === 'true')) {
        element.addEventListener(
          'click',
          function () {
            Selector.selectePayMethod(this);
          },
          true
        );
      } else {
        element.disabled = true;
        element.nextElementSibling.
          classList.add('ottu__sdk-disabled');
      }
    });

  if (documentCheckout.querySelector('#' + elements.payButton)) {
    documentCheckout.querySelector('#' + elements.payButton).addEventListener(
      'click',
      function () {
        Selector.payButtonClick();
      },
      true
    );
  }

  if (documentCheckout.querySelector('#' + elements.payCancel)) {
    documentCheckout.querySelector('#' + elements.payCancel).addEventListener(
      'click',
      function () {
        Selector.cancelButtonClick();
      },
      true
    );
  }

  documentCheckout
    .querySelectorAll('.' + elements.saveCardDeleteButton)
    .forEach((element) => {
      element.addEventListener(
        'click',
        function () {
          const number = this.attributes['data-number'].value;
          const delete_url = this.attributes.delete_url.value;
          const modal = documentCheckout.querySelector('#' + elements.modal);
          Selector.showModal(getCardRemoveModalHtml(number));
          const radioDiv = this.closest('.ottu__sdk-radio-div');
          modal.querySelector('.delete-button').addEventListener(
            'click',
            function () {
              Selector._deleteToken(delete_url, radioDiv);
            },
            true
          );
        },
        true
      );
    });

  if (applePay) {
    documentCheckout
      .querySelector('#' + elements.applePayButton)
      .addEventListener('click', () => {
        if (isWalletActive) {
          _startApplePaySession();
        } else {
          _openAppleWalletApp(mainConfig.apple_merchant_id);
        }
      });
  }

  if (stcPay) {
    let stcButton = documentCheckout.querySelector('#' + elements.stcPayButton);
    stcButton.addEventListener(
      'click',
      () => {
        Selector.selectePayMethod(stcButton);
        Selector._mobilePay('stcPay');
      },
      true
    );
  }

  if (urPay) {
    let urPayButton = documentCheckout.querySelector(
      '#' + elements.urPayButton
    );
    urPayButton.addEventListener(
      'click',
      () => {
        Selector.selectePayMethod(urPayButton);
        Selector._mobilePay('urPay');
      },
      true
    );
  }
};

const injectCSS = (css) => {
  if (!css.innerHTML) {
    return;
  }

  let sdk_child = documentCheckout.querySelector('#' + elements.sdkChild);
  css.type = 'text/css';
  sdk_child.appendChild(css);
  return css;
};

const loadCSS = (element) => {
  return new Promise((resolve, reject) => {
    const cssLink = document.createElement('link');
    cssLink.href = process.env.SERVER_URL + '/v3/css/checkout.css';
    cssLink.rel = 'stylesheet';
    cssLink.as = 'style';
    cssLink.onload = resolve;
    cssLink.onerror = reject;

    element.appendChild(cssLink);
  });
};

const renderHtml = async () => {
  try {
    documentCheckout = document
      .getElementById(mainConfig.selector)
      .attachShadow({ mode: mainConfig.shadowRootClosed ? 'closed' : 'open' });
    Selector.setSelector(documentCheckout);

    await loadCSS(documentCheckout);

    const sdkhtml = document.createElement('div');
    sdkhtml.setAttribute('id', 'ottu-js-sdk');
    sdkhtml.setAttribute('class', mainConfig.lang);
    sdkhtml.innerHTML = getHtml(mainConfig.displayMode);

    documentCheckout.appendChild(sdkhtml);

    if (!isObjectEmpty(mainConfig.theme)) {
      const overiddenTheme = getCssTheme(mainConfig.theme);
      injectCSS(overiddenTheme);
    }
  } catch (error) {
    console.log('[Error] While rendering HTML', error.message || '');
  }
};

let documentCheckout;
async function init(configs, reload) {
  if (
    !document.querySelector(
      "meta[content='width=device-width, initial-scale=1.0']"
    )
  ) {
    const viewportMeta = document.createElement('meta');
    viewportMeta.setAttribute('name', 'viewport');
    viewportMeta.setAttribute(
      'content',
      'width=device-width, initial-scale=1.0'
    );
    document.head.appendChild(viewportMeta);
  }

  if (window.location.protocol === 'https:') {
    if (!configs.formsOfPayment) {
      configs = {
        ...configs,
        formsOfPayment: [
          'applePay',
          'tokenPay',
          'ottuPG',
          'redirect',
          'googlePay',
          'stcPay',
          'urPay',
        ],
      };
    }

    const valid = setConfigs({
      ...configs,
      lang: configs.setupPreload?.language
    }, false, false);
    if (!valid) {
      return false;
    }

    if (!document.getElementById(mainConfig.selector)) {
      console.log(
        `[Error] HTML element with id ${mainConfig.selector} was not found in the DOM.`
      );
      return;
    } else if (reload) {
      replaceSdk(mainConfig);
    }

    //Initialize Sentry
    setupSentry(configs);

    if (checkRender(mainConfig)) {
      await renderHtml();

      if (
        !(
          checkFormsofPayments('tokenPay') ||
          checkFormsofPayments('ottuPG') ||
          checkFormsofPayments('redirect') ||
          checkFormsofPayments('googlePay') ||
          checkFormsofPayments('stcPay') ||
          checkFormsofPayments('urPay')
        )
      ) {
        Selector.renderOnlyApplePay();
        documentCheckout = Selector.documentCheckout;
      }

      let data;
      if (
        mainConfig.setupPreload &&
        validateSetupPreload(mainConfig.setupPreload)
      ) {
        data = mainConfig.setupPreload;
        documentCheckout
          .querySelectorAll('.skeleton-loader')
          .forEach((element) => {
            element.style.display = 'none';
          });

        if (mainConfig.setupPreload?.state == 'created') { 
          try {
            updateTxnStatus(configs.merchant_id, configs.session_id ,configs.apiKey)
          } catch (error) {
            Selector.errorResponse(error)
          }
        }
      } else {
        try {
          data = await getPaymentDetails(
            mainConfig.merchant_id,
            mainConfig.session_id,
            mainConfig.apiKey
          );
          setConfigs({
            ...mainConfig,
            lang: data.language
          });
          Selector.translateHeaders(data.language);
        } catch (error) {
          Selector.errorResponse(error);
          return;
        }
      }

      try {
        let html = '';
        let applePay = false;
        let googlePay = false;
        let stcPay = false;
        let urPay = false;
        const cancel = mainConfig.displayRejectButton && data.cancel_url;

        const apple_pay_config = findPaymentConfig(
          data.payment_services,
          'apple_pay'
        );
        if (
          _applePayAvailable() &&
          (apple_pay_config || data.apple_pay_available) &&
          checkFormsofPayments('applePay')
        ) {
          applePay = true;
          setConfigs(apple_pay_config || data.apple_pay_config, true, false);
          setConfigs(configs.applePayInit, true, false); //Always keep applePayInit after apple_pay_config so that values can be overwritten
        }

        const google_pay_config = findPaymentConfig(
          data.payment_services,
          'google_pay'
        );
        if (
          (google_pay_config || data.google_pay_available) &&
          checkFormsofPayments('googlePay')
        ) {
          googlePay = true;
          setConfigs(google_pay_config || data.google_pay_config, false, true);
          setConfigs(configs.googlePayInit, false, true); //Always keep googlePayInit after google_pay_config so that values can be overwritten
        }

        if (applePay && checkFormsofPayments('applePay')) {
          let styles = handleApplePayStyles();
          injectCSS(styles);
          var isWalletActive = mainConfig.apple_merchant_id
            ? await _isWalletActive()
            : true;
          Selector.setSelector(documentCheckout);
          Selector.showAppleButton(applePay, isWalletActive);
          documentCheckout = Selector.documentCheckout;
        }

        if (googlePay && checkFormsofPayments('googlePay')) {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://pay.google.com/gp/p/js/pay.js';
          script.onload = async () => {
            var button = await onGooglePayLoaded(documentCheckout);
            Selector.showGoogleButton(button);
            documentCheckout = Selector.documentCheckout;
          };
          document.head.appendChild(script);
        }

        const stcPayConfig =
          findPaymentConfig(data.payment_services, 'stc_pay') ||
          findPaymentConfig(data.payment_methods, 'stc_pay');
        if (stcPayConfig && checkFormsofPayments('stcPay')) {
          stcPay = true;
          let styles = handleMobilePayStyles('stcPay');
          injectCSS(styles);
          Selector.showStcButton(stcPayConfig);
        }

        const urPayConfig =
          findPaymentConfig(data.payment_services, 'urpay') ||
          findPaymentConfig(data.payment_methods, 'urpay');
        if (urPayConfig && checkFormsofPayments('urPay')) {
          urPay = true;
          let styles = handleMobilePayStyles('urPay');
          injectCSS(styles);
          Selector.showUrPayButton(urPayConfig);
        }

        const tamara_configs = findPaymentConfig(
          data.flex_methods,
          'tamara',
        );
        if (tamara_configs) {
          const {
            icon,
            flow,
            code,
            country,
            public_key,
            pre_payment_check_url,
            pre_payment_options_available,
          } = tamara_configs;

          window.tamaraWidgetConfig = {
            lang: mainConfig.lang,
            country: country,
            publicKey: public_key
          };
          
          let scriptElement = document.createElement('script');
          scriptElement.setAttribute('defer', '');
          scriptElement.setAttribute('type', 'text/javascript');
          scriptElement.setAttribute('src', process.env.TAMARA_CDN);
          document.head.appendChild(scriptElement);

          if (!pre_payment_options_available) {
            Selector.getinstallmentsOptions(pre_payment_check_url, code)
              .then((pre_payment_options) => {
                const tamaraBlock = documentCheckout.querySelector(`[data-method-id="id_${flow}"]`)
                if (pre_payment_options?.is_available) { 
                  const methodLabel = getInstallmentMehtodLabels(pre_payment_options);
                  tamaraBlock.innerHTML = getTamaraWidget(methodLabel, tamara_configs.amount);
                  tamaraBlock.classList.add(elements.accordionItem);
                  tamaraBlock.classList.remove(elements.disabled, elements.loadingInstallmentsOptionsBlock);
                } else {
                  tamaraBlock.innerHTML = installmentsNotAvailable(icon, flow);
                  tamaraBlock.classList.remove(elements.accordionItem, elements.paymentMethod, elements.loadingInstallmentsOptionsBlock);
                }
              })
          }
        }
        
        const tabby_configs = findPaymentConfig(
          data.flex_methods,
          'tabby',
        );
        if (tabby_configs) {
          const {
            flow,
            icon,
            code,
            pre_payment_check_url,
            pre_payment_options_available,
          } = tabby_configs;

          if (!pre_payment_options_available) {
            Selector.getinstallmentsOptions(pre_payment_check_url, code)
            .then((pre_payment_options) => {
              const tabbyBlock = documentCheckout.querySelector(`[data-method-id="id_${flow}"]`)
              if (pre_payment_options?.is_available) { 
                const methodLabel = $t('Pay in 4 interest-free payments');
                tabbyBlock.innerHTML = getTabbyWidget(methodLabel, icon);
                tabbyBlock.classList.add(elements.accordionItem);
                tabbyBlock.classList.remove(elements.disabled, elements.loadingInstallmentsOptionsBlock);
              } else {
                tabbyBlock.innerHTML = installmentsNotAvailable(icon, flow);
                tabbyBlock.classList.remove(elements.accordionItem, elements.paymentMethod, elements.loadingInstallmentsOptionsBlock);
              }
            })
          }
        }

        if (applePay || googlePay || stcPay || urPay) {
          documentCheckout.querySelector('.ottu__sdk-header2').innerHTML =
            $t('Or Pay With');
        } else {
          documentCheckout
            .querySelector('.wallet-buttons')
            .classList.add('d-none');
        }

        const saveCard = checkSaveCardsExist(data);
        if (saveCard && checkFormsofPayments('tokenPay')) {
          const saveCardsHtml = getSaveCardsHtml(
            data.cards,
            data.currency_code,
            data.payment_methods,
            data.payment_services
          );
          const saveCards = documentCheckout.querySelector(
            '#' + elements.savedCards
          );
          saveCards.innerHTML = saveCardsHtml;
          setEventListenerForRadioButtons(
            saveCards.querySelectorAll('.ottu__sdk-input-ccv')
          );
        } else {
          documentCheckout
            .querySelector('#' + elements.savedCards)
            .classList.add('d-none');
        }

        if (
          checkFormsofPayments('redirect') ||
          checkFormsofPayments('googlePay') ||
          checkFormsofPayments('applePay') ||
          checkFormsofPayments('ottuPG') ||
          checkFormsofPayments('stcPay') ||
          checkFormsofPayments('urPay')
        ) {
          window.redirectSubmitUrl = data.submit_url;
          documentCheckout
            .querySelectorAll('.' + elements.blockMethodsLoader)
            .forEach(loader => {
              loader.remove();
            });
            
          html += getPaymentMethodsHtml(
            data.payment_methods,
            data.can_save_card,
            data.currency_code,
            data.flex_methods
          );

          if (
            !getPaymentMethodsHtml(
              data.payment_methods,
              data.can_save_card,
              data.currency_code,
              data.flex_methods
            ) &&
            !(saveCard && checkFormsofPayments('tokenPay'))
          ) {
            documentCheckout
              .querySelectorAll('.card-only-display')
              .forEach((element) => {
                element.style.display = 'none';
              });
            documentCheckout
              .querySelector('#' + elements.blockButtons)
              .classList.add('d-none');
          }

          documentCheckout.querySelector(
            '#' + elements.blockMethods
          ).innerHTML = html;
        } else {
          documentCheckout
            .querySelector('#' + elements.blockMethods)
            .classList.add('d-none');
        }

        setConfigs(
          {
            ...mainConfig,
            public_key_url: data.public_key_url,
            cancel_url: data.cancel_url,
            customer_phone: data.customer_phone,
          },
          false,
          false
        );

        if (data.amount && data.currency_code) {
          documentCheckout.querySelector('#' + elements.totalBlock).innerHTML =
            getTotalAmountHtml();
          documentCheckout
            .querySelectorAll(`.${elements.amount}`)
            .forEach((element) => {
              element.innerHTML = data.amount;
            });
          documentCheckout
            .querySelectorAll(`.${elements.currency}`)
            .forEach((element) => {
              element.innerHTML = data.currency_code;
            });
        }
        documentCheckout
          .querySelector('#' + elements.blockButtonsLoader)
          .remove();

        if (
          (data.payment_methods?.length ||
          data.flex_methods?.length) &&
          (
            checkFormsofPayments('tokenPay') ||
            checkFormsofPayments('redirect') ||
            checkFormsofPayments('ottuPG') ||
            checkFormsofPayments('googlePay') ||
            checkFormsofPayments('stcPay') ||
            checkFormsofPayments('urPay')
          )
        ) {
          documentCheckout.querySelector(
            '#' + elements.blockButtons
          ).innerHTML = getButtonsHtml(cancel);
        } else {
          if (applePay && checkFormsofPayments('applePay')) {
            setConfigs(data.apple_pay_config, true, false);
            documentCheckout
              .querySelector('#' + elements.blockButtons)
              .classList.add('d-none');
          } else if (isOnlyApplePay(data) && !_applePayAvailable()) {
            documentCheckout.querySelector(
              '#' + elements.blockButtons
            ).innerHTML = getApplePayNotSupportedHtml();
            documentCheckout
              .querySelector('#' + elements.blockButtons)
              .classList.remove('d-none');
          }
        }

        setEvents(applePay, isWalletActive, stcPay, urPay);
      } catch (error) {
        Selector.errorResponse(error);
      }
    }
  } else {
    documentCheckout = document
      .getElementById(configs.selector)
      .attachShadow({ mode: 'closed' });
    Selector.setSelector(documentCheckout);
    const css =
      '<link href="' +
      process.env.SERVER_URL +
      '/v3/css/checkout.css" type="text/css" rel="stylesheet" media="screen,print"></link>';
    documentCheckout.innerHTML = css + getHtmlErorrModal();
    Selector.showModal(
      getFailedModalHtml("Website doesn't run on non https pages", $t('Error')),
      null,
      'info'
    );
  }
}

function reload() {
  init(mainConfig, true);
}

function showPopup(type, message, response) {
  if ((type && type == 'error') || type == 'success' || type == 'redirect') {
    if (!response) {
      Selector.showModal(
        getPopupModalHtml(type, message || 'Something Went Wrong.'),
        null,
        'info'
      );
    } else {
      Selector.showModal(
        getPopupModalHtml(type, message, response),
        null,
        'info'
      );
    }
  } else {
    Selector.showModal(
      getFailedModalHtml(
        'Please specify a valid type in Checkout.showPopup()',
        $t('Error')
      ),
      null,
      'info'
    );
  }
}

exports.pay = Selector.pay;
exports.init = init;
exports.reload = reload;
exports.showPopup = showPopup;
